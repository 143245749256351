<template>
  <v-container fluid>
    <page-header title="換領預約"> 
      <template #rightSlot>
        <v-btn depressed color="primary accent-4" class="white--text" :to="{ name: 'CreateRedeem' }">新增</v-btn>
      </template>
    </page-header>

    <datatable
      :tableHeaders="tableHeaders"
      :tableData="tableData"
      :page.sync="tablePage"
      :pageLimit.sync="tablePageLimit"
      :itemTotal="tableItemTotal"
      :isLoading="tableLoading"
      actionViewRouteLink="RedeemDetail"
      @options-update="getList()"
      searchPlaceholder="搜尋用戶名稱/電話"
      enableSearch
      @search-input="filter.filter_student = $event"
      @search-clicked="search()"
    >
      <template #custom-toolbar-slot>
        <form-select
          class="my-2 mr-3"
          :fieldValue.sync="filter.redeem_status"
          :options="$redeemStatusOptions"
          hideDetails
          enabledClear
          placeholder="搜尋換領狀態"
          @changed="search()"
        />
        <form-date-picker
          class="my-2"
          :fieldValue.sync="filter.redeem_date"
          hideDetails
          placeholder="搜尋換領日期"
          @changed="search()"
        />
      </template>
      <template #item.redeem_code="{item}">
        {{ $Formatter.pad(item.id, 4) }}
      </template>
      <template #item.redeem_status="{item}">
        {{ item.redeem_status | formatRedeemStatus }}
      </template>
    </datatable>
  </v-container>
</template>

<script>
import Datatable from '@/components/Datatable.vue'
import FormSelect from '@/components/form/FormSelect.vue'
import FormDatePicker from '@/components/form/FormDatePicker.vue'

export default {
  name: 'RedeemList',
  components: {
    Datatable,
    FormSelect,
    FormDatePicker,
  },
  data: () => ({
    tableLoading: false,
    tablePage: 1,
    tablePageLimit: 10,
    tableData: [],
    tableItemTotal: 0,

    // --- static data
    tableHeaders: [
      { text: '換領編號', value: 'redeem_code' },
      { text: '用戶名稱', value: 'username' },
      { text: '禮品名稱', value: 'gift_name' },
      { text: '禮品所需積分', value: 'gift_credit' },
      { text: '換領日期', value: 'date' },
      { text: '換領時間', value: 'time' },
      { text: '換領狀態', value: 'redeem_status' },
      { text: '', value: 'actionView', align: 'end', width: 150 },
    ],

    filter: {
      redeem_date: '',
      redeem_status: '',
      filter_student: '',
    },
  }),
  methods: {
    getQueries() {
      const query = this.$route.query
      if (this.$validate.regexNumber(query['p']) && parseInt(query['p']) > 0) {
        this.tablePage = parseInt(query['p'])
      }

      if (this.$validate.regexNumber(query['l']) && parseInt(query['l']) > 0) {
        this.tablePageLimit = parseInt(query['l'])
      }

      if (query['q']) {
        this.filter.filter_student = query['q']
      }
      if (query['d']) {
        this.filter.redeem_date = query['d']
      }
      if (query['s']) {
        this.filter.redeem_status = query['s']
      }
      this.getList(true)
    },

    async getList(pageInit = false) {
      if (!pageInit) {
        const newQuery = {
          p: this.$validate.regexNumber(this.tablePage) && parseInt(this.tablePage) > 1 ? this.tablePage : undefined,
          l:
            this.$validate.regexNumber(this.tablePageLimit) && parseInt(this.tablePageLimit) !== 10
              ? this.tablePageLimit
              : undefined,
          q: this.filter.filter_student ? this.filter.filter_student : undefined,
          d: this.filter.redeem_date ? this.filter.redeem_date : undefined,
          s: this.filter.redeem_status ? this.filter.redeem_status : undefined,
        }

        const validNewQuery = this.$common.validNewQuery(this.$route.query, newQuery)
        if (validNewQuery) {
          this.$router.replace({ query: newQuery })
        }
      }

      this.tableLoading = true

      try {
        let payload = {
          limit: this.tablePageLimit,
          page: this.tablePage - 1,
          join_student: true,
        }

        if (this.filter.filter_student) {
          payload['filter_student'] = this.filter.filter_student
        }
        if (this.filter.redeem_date) {
          payload['redeem_date'] = this.filter.redeem_date.replace(/-/g, "");
        }
        if (this.filter.redeem_status) {
          payload['redeem_status'] = this.filter.redeem_status
        }

        const user = this.getCurrentUserData()
        const { data, total } = await this.$Fetcher.GetRedeems(payload, user.id, user.token)
        this.tableItemTotal = total
        this.tableData = data.map(el => {
          let date = el.redeem_date;
          if (date.length === 8) {
            date = `${date.substring(0, 4)}-${date.substring(4,6)}-${date.substring(6,8)}`
          }
          return {
            id: el.id,
            username: el.student ? el.student.name : '',
            gift_name: el.gift_data ? el.gift_data.name : '',
            gift_credit: el.gift_data ? el.gift_data.point_required : '',
            date: date,
            time: el.redeem_time,
            redeem_status: el.redeem_status,
          }
        })
      } catch (err) {
        this.$common.error(err)
        if (err) {
          this.$store.dispatch('toggleSnack', {
            type: 'error',
            message: err,
          })
        }
        this.tableData = []
      } finally {
        this.tableLoading = false
      }
    },

    search() {
      this.tablePage = 1;
      this.getList();
    }
  },

  mounted() {
    this.getQueries()
  },
}
</script>
